import React, { Component } from 'react';
import Navbar from './components/Navbar'
import './App.css';
// import './css/navbar.css'
import { BrowserRouter, Route } from 'react-router-dom'
import About from './components/About';
import Services from './components/Services';
import Home from './components/Home'
import Contact from './components/Contact'
import Portfolio from './components/Portfolio'
import { Layout, BackTop } from 'antd';
// import { useMediaQuery } from 'react-responsive';
import SideDrawer from '../src/components/SideDrawer';
import Backdrop from '../src/components/Backdrop';



  // const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 })
  // const isBigScreen = useMediaQuery({ minDeviceWidth: 1824 })
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  // const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 })
  // const isPortrait = useMediaQuery({ orientation: 'portrait' })
  // const isRetina = useMediaQuery({ minResolution: '2dppx' })

const {  Footer } = Layout;
class App extends Component {
  state = {
    sideDrawerOpen: false
  };


  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
  });
  };


backdropClickHandler = () => {
  this.setState({sideDrawerOpen: false})

};

  render() {  
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler}  />
    }

  


    return (
      <div style={{height: '100%'}}>
    <BrowserRouter>
    <div id="page-container">
   <div id="content-wrap">
<Navbar drawerClickHandler={this.drawerToggleClickHandler} />
<SideDrawer show={this.state.sideDrawerOpen} />
{backdrop}
{/* <main style={{lineHeight: 70}}> */}
<Route exact path='/' component={Home} />
<Route exact path='/about' component={About} />
<Route exact path='/services' component={Services} />
<Route exact path='/contact' component={Contact} />
<Route exact path='/portfolio' component={Portfolio} />
{/* </main> */}
</div>


    </div>
    <Footer style={{
    borderTop: "1px solid #E7E7E7", textAlign: "center", padding: "20px", position: "reletive", left: "0", bottom: "0",
height: "60px", width: "100%" }}>A FO Solution ©2020 

<div>
    <BackTop />
  </div>
</Footer>
    </BrowserRouter>
    </div>
    );

  }

}

export default App;
