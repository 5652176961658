import React from 'react'
import {  Card } from 'antd'
// import ContactForm from './ContactForm'
import handshake from '../images/handshake.png';




const { Meta } = Card;
// const { Option } = Select;
// const AutoCompleteOption = AutoComplete.Option;
const Contact = () => {
return (

<div>
<section style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '70vh'}}>
        
<Card
    hoverable
    style={{ width: 350 }}
    cover={<img alt="let me help youuuu" src={ handshake }/>}
  >
    <Meta title="Email me at fokafor21@hotmail.co.uk" description="Attempt to respond within 48 hours" />
  </Card>
  




        </section>
<br />
<br />
<br />
<br />
<br />


  {/* Expected for version 2 */}
{/* <ContactForm /> */}


</div>
);
}

export default Contact;