import React  from 'react'
// import image from '../images/image.png';
import { Divider, Card, Button, Row, Col, Icon, Modal } from 'antd'
import { Link } from 'react-router-dom'
import Table from './Table.js'

          class Services extends React.Component {
            state = {
              modal1Visible: false,
              modal2Visible: false,
              modal3Visible: false,
            }
            setModal1Visible(modal1Visible) {
              this.setState({ modal1Visible });
            }
            setModal2Visible(modal2Visible) {
              this.setState({ modal2Visible });
            }
            setModal3Visible(modal3Visible) {
              this.setState({ modal3Visible });
            };
      
          render() {
            return (
              <div>
                <section style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '30vh'}}>
             <Card title="Services" bordered={true} style={{width: 600, backgroundColor: '#f5f6f7'}}>
            
             FO Solutions provides different custom WordPress packages depending on your online needs. You can find the services provided in the table below. For React projects please contact me directly for a quote!
 <br></br>
             <span style={{fontWeight: 'bold'}}>Delivery Time** - May vary depending on the amount of design revisions requested and size of the requirement</span>
            
         </Card>
         </section>

         <br />
         <br />
         {/* Modal */}
<div id="modal" style={{align: 'center'}}>
{/* <ol style={{listStyle:"none"}}> */}
<Button size="large" type="primary" onClick={() => this.setModal1Visible(true)}>Bronze</Button>
        <Modal
          title="STARTING FROM £100"
          style={{ top: 20 }}
          visible={this.state.modal1Visible}
          onOk={() => this.setModal1Visible(false)}
          onCancel={() => this.setModal1Visible(false)}
          // cancelText="Close"
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >
           <span style={{ backgroundColor: '#cd7f32', color: 'white', display: 'inline-block', paddingLeft:8, paddingRight: 8, textAlign: 'center' }}>Bronze</span>
      <h4>Basic website for your small business with up to 5 unique pages included.</h4>
       <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Responsive Website</p>
    </div>
    <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Brand Logo</p>
    </div>
    <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>HD Images</p>
     </div>
     <div>
    <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Web Hosting</p>
     </div>
     <div>
    <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Site Content & Research</p>
    </div>
    <br />
 <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
     <Button type="primary" style={{ paddingLeft: 70, paddingRight: 70 }}><Link to ="/contact">GET IN TOUCH</Link></Button>
     </div>
        </Modal>
        <br /><br />
        {/* Silver */}
        <Button size="large" type="primary" onClick={() => this.setModal2Visible(true)}>Silver</Button>
        <Modal
          title="STARTING FROM £200"
          wrapClassName="vertical-center-modal"
          visible={this.state.modal2Visible}
          onOk={() => this.setModal2Visible(false)}
          onCancel={() => this.setModal2Visible(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >
          <span style={{ backgroundColor: '#c0c0c0', color: 'white', display: 'inline-block', paddingLeft:8, paddingRight: 8, textAlign: 'center' }}>Silver</span>
     <h4> Bronze features + standard quality website with extra details, up to 10 pages included</h4>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>SSL Certificate</p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Free Domain</p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>10 Webpages</p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Quick Delivery Time</p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Revisions x2</p>
     </div>
     <div>
     <br />
     <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
     <Button type="primary" style={{ paddingLeft: 70, paddingRight: 70 }}><Link to ="/contact">GET IN TOUCH</Link></Button>
     </div>
     </div>
        </Modal>
        <br />
        <br />
        {/* Gold */}
<Button size="large" type="primary" onClick={() => this.setModal3Visible(true)}>Gold</Button>
        <Modal
          title="STARTING FROM £350"
          style={{ top: 40 }}
          visible={this.state.modal3Visible}
          onOk={() => this.setModal3Visible(false)}
          onCancel={() => this.setModal3Visible(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >
          <span style={{ backgroundColor: '#ffd700', color: 'white', display: 'inline-block', paddingLeft:8, paddingRight: 8, textAlign: 'center' }}>Gold</span>
    
   <h4> Bronze & Silver features + Up to 15 unique pages, custom designs included, all essentials + MORE!!!</h4>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Email Package for Businesses</p>
     </div>
     <div>
    <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>SEO Optimization</p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>E-Commerce Functionality</p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Website Aftercare Package Included </p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Super Quick Delivery Time</p>
     </div>
     <br />
     <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
     <Button type="primary" style={{ paddingLeft: 70, paddingRight: 70 }}><Link to ="/contact">GET IN TOUCH</Link></Button>
     </div>
        </Modal>
        {/* </ol> */}
        <br />
        <br />
        <br />
        <br />
      </div>
            


                {/* <section>

// </section> */}
 <div id="mobile-card">
         {/* Scroll button here */}
         <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
         <Button type="primary" shape="circle" icon="caret-down" href="#table" alt="scroll down"/>
         </div>
         <br />

        
 {/* <section style={{display: 'static',  justifyContent:'center', alignItems:'center', height: '25vh', paddingLeft: '50px', paddingRight: '50px'}}> */}

 <section style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#f5f6f7'}}>

{/* Bronze Card */}
         <Row gutter={16}>
      <Col span={8}>
       <Card style={{ width: 300, borderRadius: 15, textAlign: 'left' }}>
       <span style={{ backgroundColor: '#cd7f32', color: 'white', display: 'inline-block', paddingLeft:8, paddingRight: 8, textAlign: 'center' }}>Bronze</span>
       <h2>STARTING FROM £100</h2>
      <h4>Basic website for your small business with up to 5 unique pages included.</h4>
       <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Responsive Website</p>
    </div>
    <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Brand Logo</p>
    </div>
    <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>HD Images</p>
     </div>
     <div>
    <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Web Hosting</p>
     </div>
     <div>
    <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Site Content & Research</p>
    </div>
    <br />
 <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
     <Button type="primary" style={{ paddingLeft: 70, paddingRight: 70 }}><Link to ="/contact">GET IN TOUCH</Link></Button>
     </div>
   </Card>
       </Col>

{/* Silver Card */}
       <Col span={8}>
        <Card style={{ width: 300, borderRadius: 15, textAlign: 'left' }}>
         <span style={{ backgroundColor: '#c0c0c0', color: 'white', display: 'inline-block', paddingLeft:8, paddingRight: 8, textAlign: 'center' }}>Silver</span>
     <h2>STARTING FROM £200</h2>
     <h4> Bronze features + standard quality website with extra details, up to 10 pages included</h4>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>SSL Certificate</p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Free Domain</p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>10 Webpages</p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Quick Delivery Time</p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Revisions x2</p>
     </div>
     <div>
     <br />
     <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
     <Button type="primary" style={{ paddingLeft: 70, paddingRight: 70 }}><Link to ="/contact">GET IN TOUCH</Link></Button>
     </div>
     </div>

   </Card>
       </Col>
       <Col span={8}>
       <Card style={{ width: 300, borderRadius: 15, textAlign: 'left' }}>
       <span style={{ backgroundColor: '#ffd700', color: 'white', display: 'inline-block', paddingLeft:8, paddingRight: 8, textAlign: 'center' }}>Gold</span>
       <h2>STARTING FROM £350</h2>
   <h4> Bronze & Silver features + Up to 15 unique pages, custom designs included, all essentials + MORE!!!</h4>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Email Package for Businesses</p>
     </div>
     <div>
    <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>SEO Optimization</p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>E-Commerce Functionality</p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Website Aftercare Package Included </p>
     </div>
     <div>
     <Icon type="check-circle" style={{ paddingRight: 5 }}/><p style={{ display: 'inline-block', paddingRight: 1.5 }}>Super Quick Delivery Time</p>
     </div>
     <br />
     <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
     <Button type="primary" style={{ paddingLeft: 70, paddingRight: 70 }}><Link to ="/contact">GET IN TOUCH</Link></Button>
     </div>
   </Card>
      </Col>
    </Row>
     </section>
     </div>
     <br />
     <br />
     <br />

 <Divider></Divider>
  <div id='table'>
    {/* <h2>Header</h2>
     <h4>Desc</h4> */}
 </div>

 <section>

 {/* <Table dataSource={dataSource} columns={columns} /> */}

 <Table />
 </section>



              </div>
            );
          }
        }
export default Services