import React from 'react';
import { Link } from 'react-router-dom'

import '../css/SideDrawer.css'


const sideDrawer = props => {
    let drawerClasses = 'side-drawer';
    if (props.show) {
        drawerClasses = 'side-drawer open';
    }
    return(
    <nav className={drawerClasses}>
        <ul>
      <li><Link to ="/">Home</Link></li>
      <li><Link to ="/about">About Me</Link></li>
      <li><Link to ="/services">Services</Link></li>
      <li><Link to ="/portfolio">Portfolio</Link></li>
      <li><Link to ="/contact">Contact</Link></li>
    </ul>

    </nav>);
};

export default sideDrawer;