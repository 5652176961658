// install ant-design
import React from 'react';
import { Link } from 'react-router-dom';
import fao_logo from '../images/fao_logo.png'


import DrawerToggleButton from './DrawerToggleButton';
import '../css/Navbar.css';



const navbar = props => (
<header className="navbar">
  <nav className="navbar__navigation">
    <div className="navbar__toggle-button">

    <DrawerToggleButton  click={props.drawerClickHandler} />

    </div>
    <div className="navbar__logo"><Link to ="/"><img
        alt="website_logo"
        src={ fao_logo }
      /></Link></div>





    <div className="spacer" />
  <div className="navbar_navigation-items">
    <ul>
      <li><Link to ="/">Home</Link></li>
      <li><Link to ="/about">About Me</Link></li>
      <li><Link to ="/services">Services</Link></li>
      <li><Link to ="/portfolio">Portfolio</Link></li>
      <li><Link to ="/contact">Contact</Link></li>
    </ul>
  </div>
  
  
  
  
  
  </nav>
</header>
)

 
export default navbar;
  
// )

// function Navbar() {
//     return (

//         <Layout className="layout">

//     <Header>
//       <nav> 
        
//         <div>
//           <DrawerToggleButton />
          
//           </div>     
//       <Menu
//         // theme="dark"
//         mode="horizontal"
//         // to change depending on what page the user is on
//         // defaultSelectedKeys={['1']}

//         // style={{ lineHeight: '64px' }}
//         align="right"
//       >

//         {/* Add react rendering  */}
//         <Menu.Item key="1"><Link to ="/">Home</Link></Menu.Item>
//         <Menu.Item key="2"><Link to ="/about">About Me</Link></Menu.Item>
//         <Menu.Item key="3"><Link to ="/services">Services</Link></Menu.Item>
//         <Menu.Item key="4"><Link to ="/portfolio">Portfolio</Link></Menu.Item>
//         <Menu.Item key="5"><Link to ="/contact">Contact</Link></Menu.Item>
        
//       </Menu>

  
//       </nav>
//     </Header>

//     );
// export default navbar;
    

