import React from 'react'
import { Card } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPython, faReact, faNode, faCss3, faJs, faHtml5 } from '@fortawesome/free-brands-svg-icons';
import '../css/About.css'


const About = () => {
    return(
<div>


<Card>
    <p
      style={{
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.85)',
        // marginBottom: 16,
        fontWeight: 500,
      }}
    >
    </p>
    <Card type="inner" title="About Me">
    London based Full-Stack Developer & Business Analyst. Experience in technical support such as troubleshooting and software solutions. Confident with developing apps and websites, with particular technical expertise using Python and JavaScript.
    </Card>
    <Card
      style={{ marginTop: 16 }}
      type="inner"
      title="Experience"
    >
      <h2>Worked on serveral projects such as:</h2>
      <h4>
      API development for wearable technology (Python)
      <br />
      <br />
      Business specific Websites using CMS (Wordpress)
      <br />
      <br />
      Fullstack Web development using a MERN stack (mySQL, ExpressJS, React & NodeJS)
      </h4>
    {/* <ul>
    <li style={{listStyle: 'none', display: 'flex'}}><h4>Worked on serveral projects such as:</h4></li> 
  <li style={{listStyleType: 'circle'}}>API development for wearable technology (Python)</li>
  <li style={{listStyleType: 'circle'}}>Business specific Websites using CMS (Wordpress)</li> 
  <li style={{listStyleType: 'circle'}}>Fullstack Web development using a MERN stack (mySQL, ExpressJS, React & NodeJS)</li>
  
</ul>   */}
    </Card>
  </Card>

<Card bordered={false}>
    <p
      style={{
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.85)',
        marginBottom: 16,
        fontWeight: 500,
      }}
    >
    </p>
    <Card type="inner" title="Programming Languages & Tools" >
    <div style={{color: 'black'}}>
<FontAwesomeIcon style={{fontSize: '3rem'}} icon={faNode}></FontAwesomeIcon>
<FontAwesomeIcon style={{marginLeft: 10, fontSize: '3rem'}} icon={faReact}></FontAwesomeIcon>
<FontAwesomeIcon style={{marginLeft: 10, fontSize: '3rem'}} icon={faPython}></FontAwesomeIcon>
<FontAwesomeIcon style={{marginLeft: 10, fontSize: '3rem'}} icon={faCss3}></FontAwesomeIcon>
<FontAwesomeIcon style={{marginLeft: 10, fontSize: '3rem'}} icon={faJs}></FontAwesomeIcon>
<FontAwesomeIcon style={{marginLeft: 10, fontSize: '3rem'}} icon={faHtml5}></FontAwesomeIcon>
  </div>
    </Card>

  </Card>
<br />
<br />
        </div>

    )}

    export default About