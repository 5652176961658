import React from 'react'
import { Divider, Button, Row, Col } from 'antd'
// import { Link, NavLink } from 'react-router-dom'
import image from '../images/image.png'
import parked from '../images/parked.png'
// import acn from '../images/acn.png'
import image_d from '../images/image_d.png'
import parked_d from '../images/parked_d.png'
// import acn_d from '../images/acn_d.png'
import mek_b from '../images/mek_b.png'
import '../css/Portfolio.css'



const Portfolio = () => {
return (

<div style={{padding: 40}}>
    

      <Row>
      <Col id="mobile_image" span={12}><img width='300' height='200' alt="example" src={ image } /></Col>
      <Col id="desktop_image" span={12}><img  alt="example" src={ image_d } /></Col>
    <Col span={12}><h2>Winnish Care Agency</h2>
    <h4>A domiciliary care agency in North West London</h4>
    {/* <div style={{paddingLeft: 1.5, display : 'inline-block'}}><Button type="primary">Preview</Button></div> */}
    <div style={{paddingLeft: 1.5, display : 'inline-block'}}><Button><a href="https://www.winnishcareagency.com/" target="_blank" without rel="noopener noreferrer">Visit Website</a></Button></div>
    
      </Col>
    </Row>
    
    

    <br />
      <Divider style={{backgroundColor: 'grey'}}></Divider>

      <Row>
      <Col id="mobile_image" span={12}><img width='300' height='200' alt="example" src={ parked } /></Col>
      <Col id="desktop_image" span={12}><img  alt="example" src={ parked_d } /></Col>
    <Col span={12}><h2>Park Education Training Centre</h2>
    <h4>Park Education & Training Centre offers a range of courses relevant to the job market</h4>
    {/* <div style={{paddingLeft: 1.5, display : 'inline-block'}}><Button type="primary">Preview</Button></div> */}
    <div style={{paddingLeft: 1.5, display : 'inline-block'}}><Button><a href="https://www.parkeducation.co.uk" target="blank" without rel="noopener noreferrer">Visit Website</a></Button></div>
      </Col>
    </Row>
    <br />

    <Divider style={{backgroundColor: 'grey'}}></Divider>

    <Row>
    <Col id="mobile_image" span={12}><img width='300' height='200' alt="example" src={ mek_b } /></Col>
    <Col id="desktop_image" span={12}><img  alt="example" src={ mek_b } /></Col>
    <Col span={12}><h2>Meks Business LTD</h2>
    <h4>non profit organisation</h4>
    {/* <div style={{paddingLeft: 1.5, display : 'inline-block'}}><Button type="primary">Preview</Button></div> */}
    <div style={{paddingLeft: 1.5, display : 'inline-block'}}><Button><a href="https://www.meksbusinessltd.com/" target="_blank" without rel="noopener noreferrer">Visit Website</a></Button></div>
    </Col>

    </Row>
    
    <br />

    </div>

);
}

export default Portfolio;