import React, { Component } from 'react';
import { Icon } from 'antd'
import '../css/Services.css'

class Table extends Component {
    render() {

      return (
        <div id="table">
<section style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
<table style={{ border: '1',fontFamily: 'arial, sans-serif', borderCollapse: 'collapse', width: '100%',backgroundColor: '#f2f2f2', tableLayout: 'fixed'}}>
    {/*  fontSize: '20px' */}
  <tr style={{ backgroundColor: 'white'}}>
    <th></th>  
    <th>Bronze</th>
    <th>Silver</th>
    <th>Gold</th>
  </tr>
  <tr style={{ backgroundColor: 'white'}}>
    <td>Webpages</td>  
    <td style={{ padding: 10 }}>5</td>
    <td>10</td>
    <td>15</td>
  
  </tr>
  <tr>
    <td style={{ padding: 10 }}>Responsive Website</td>  
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px'  }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
  
  </tr>

  <tr style={{ backgroundColor: 'white'}}>
    <td style={{ padding: 10 }}>Brand Logo</td>  
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
  
  </tr>

  <tr>
    <td style={{ padding: 10 }}>HD Sourced Images</td>  
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
  
  </tr>

  <tr style={{ backgroundColor: 'white'}}>
    <td style={{ padding: 10 }}>Contact Form</td>  
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
  
  </tr>

  <tr>
    <td style={{ padding: 10 }}>Web Hosting</td>  
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px'}}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
  
  </tr>

  <tr style={{ backgroundColor: 'white'}}>
    <td style={{ padding: 10 }} >Site Content & Research</td>  
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
  
  </tr>
  
  <tr>
    <td style={{ padding: 10 }}>SSL Certificate</td>  
    <td><Icon type="close-circle" style={{ color: 'red', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>

  
  </tr>

  <tr style={{ backgroundColor: 'white'}}>
    <td style={{ padding: 10 }} >Free Domain Name</td>  
    <td><Icon type="close-circle" style={{ color: 'red', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
  </tr>

  <tr>
    <td style={{ padding: 10 }}>Email Package</td>  
    <td><Icon type="close-circle" style={{ color: 'red', fontSize: '20px' }}/></td>
    <td><Icon type="close-circle" style={{ color: 'red', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
  </tr>

  <tr style={{ backgroundColor: 'white'}}>
    <td style={{ padding: 10 }} >SEO Optimization</td>  
    <td><Icon type="close-circle" style={{ color: 'red', fontSize: '20px' }}/></td>
    <td><Icon type="close-circle" style={{ color: 'red', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
  </tr>

  <tr>
    <td style={{ padding: 10 }}>E-Commerce Functionality</td>  
    <td><Icon type="close-circle" style={{ color: 'red', fontSize: '20px' }}/></td>
    <td><Icon type="close-circle" style={{ color: 'red', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
  </tr>

  <tr style={{ backgroundColor: 'white'}}>
    <td style={{ padding: 10 }} >Website aftercare</td>  
    <td><Icon type="close-circle" style={{ color: 'red', fontSize: '20px' }}/></td>
    <td><Icon type="close-circle" style={{ color: 'red', fontSize: '20px' }}/></td>
    <td><Icon type="check-circle" style={{ color: 'green', fontSize: '20px' }}/></td>
  </tr>

  <tr>
    <td>Revisions</td>  
    <td style={{ padding: 10 }}>2</td>
    <td>2</td>
    <td>3</td>
  
  </tr>

  <tr style={{ backgroundColor: 'white'}}>
    <td>Delivery Time**</td>  
    <td style={{ padding: 10 }}>7 Working Days</td>
    <td>5 Working Days</td>
    <td>3 Working Days</td>
  
  </tr>


  <br></br>
</table>
<br />
</section>
</div>



      );
    }
  }
  
  export default Table;