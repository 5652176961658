import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Row, Col } from 'antd'
import banner from '../images/banner.png'
import wordpress from '../images/wordpress.png';
import frontend from '../images/frontend.png';
import backend from '../images/backend.png';
import '../css/Home.css'

// const { Meta } = Card;
const Home = () => {
    return(
        <div id="banner" className="grid">
          <div style={{backgroundColor: '#f5f6f7', textAlign: 'center'}}>
          <h1> Freelance Web Designer and Developer based in the UK. Highly experienced in designing and developing custom Wordpress websites and React based projects. </h1>
          </div>

<br />
          <div id="banner_home" style={{backgroundColor: '#f5f6f7', padding: '12px'}}>

<Row>
      <Col span={6} push={14}>
        <h1>Fully responsive, bespoke Wordpress CMS websites & Web Applications</h1>
      </Col>
      <Col span={18} pull={6}>
        <img style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: 750, height: 350, paddingLeft: '20px'}} alt="home banner" src={ banner } />

        <Button 
    style={{position: 'absolute', left: 1100, bottom: 50}}
    type="primary"><Link to ="/portfolio">View Portfolio</Link></Button>
    
       
      </Col>
    </Row>



</div>
          
{/* <p>
  <Divider />
</p> */}

<section id="section-b" className="grid">
  <ul>
    <li>
      <div className="card">
      <img
        alt="wordpress"
        src={ wordpress }
      />
      <div className="card-content">
        <h3 className="card-title">Wordpress</h3>
        <p>Custom Website Design Services. Get a modern website for your business. We specialise in WordPress Website Design.</p>
      </div>

      </div>
    </li>
    
    <li>
      <div className="card">
      <img
        alt="frontend"
        src={ frontend }
      />
      <div className="card-content">
        <h3 className="card-title">Frontend</h3>
        <p>A user-friendly website strengthens your company’s brand while ensuring ease of use and simplicity for your audience.</p>
      </div>

      </div>
    </li>

    <li>
      <div className="card">
      <img
        alt="backend"
        src={ backend }
      />
      <div className="card-content">
        <h3 className="card-title">Back-end</h3>
        <p>The backend usually consists of three parts: a server, an application, and a database. (NodeJS | ReactJS | mySQL)</p>
      </div>

      </div>
    </li>
  </ul>
</section>

<div className="content-wrap">
    <Button type="primary"><Link to ="/services">Checkout Services</Link></Button>
    </div>

<br></br>
<br></br>
<br></br>



    </div>
    )
}

export default Home